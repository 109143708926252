<template>
  <v-container fluid tag="section">
    <div>
      <h1 class="insights-title">{{ $t("content") }}</h1>
      <h2
        class="insights-sub-title"
        v-if="carouselItems && carouselItems.length"
      >
        {{ $t("insights_dxa") }}
      </h2>
      <div class="carousel-loading" v-if="carouselItemsLoading">
        <v-progress-circular
          indeterminate
          size="60"
          color="primary"
        ></v-progress-circular>
      </div>
      <div class="insights-container" v-else>
        <div
          class="carousel-container"
          v-if="!carouselItemsLoading && carouselItems && carouselItems.length"
        >
          <v-carousel v-model="model" cycle height="255px">
            <v-carousel-item
              @click="handleOpenVidoDialog(carouselItem)"
              style="cursor: pointer"
              v-for="(carouselItem, i) in carouselItems"
              :key="i"
            >
              <v-sheet height="100%">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div>
                    <img
                      style="max-width: 100%"
                      :src="gs.get_photo_path(carouselItem.urlThumb)"
                      :alt="carouselItem.thumbFileName"
                    />
                  </div>
                </div>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </div>
        <div v-if="language === LanguageEnum.pt">
          <h2 class="insights-sub-title" v-if="!carouselItems.length">
            {{ $t("insights_dxa") }}
          </h2>
          <div class="blog-container">
            <div class="blog-content">
              <h2 class="blog-title">{{ $t("blog_dxa_title") }}</h2>
              <p class="blog-sub-title">
                {{ $t("blog_dxa_sub_title") }}
              </p>
              <a
                href="https://www.dxainvest.com/blog"
                target="_blank"
                class="blog-link"
                >{{ $t("read_blog") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="files-loading" v-if="filesLoading">
      <v-progress-circular
        indeterminate
        size="60"
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <MaterialsTable
        :files="materialsItems"
        v-if="!filesLoading && materialsItems.length"
      />
    </div>
    <v-dialog v-if="videoDialog" v-model="videoDialog" scrollable>
      <VideoDialog :video="videoToOpen" />
    </v-dialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import { mapGetters } from "vuex";
import { LanguageEnum, LanguageTextEnum } from "@/shared/enums/LanguageEnum";
import GeneralServices from "@/services/GeneralServices";
import MaterialsTable from "./MaterialsTable.vue";
import VideoDialog from "../MaterialsB2BVideos/VideoDialog.vue";

export default {
  name: "MaterialB2BPartnerOrBankerComponent",
  components: {
    MaterialsTable,
    VideoDialog,
  },
  data() {
    return {
      carouselItems: [],
      materialsItems: [],
      apiService: new ApiService(),
      language: null,
      LanguageEnum,
      LanguageTextEnum,
      model: 0,
      gs: new GeneralServices(),
      carouselItemsLoading: false,
      videoToOpen: null,
      videoDialog: false,
      filesLoading: false,
    };
  },
  async created() {
    const localStorageLanguage = window.localStorage.getItem("lang");

    this.getLanguage(localStorageLanguage);
    await this.getCarouselItems();
    await this.getMaterialsItems();
  },
  computed: {
    ...mapGetters({
      lang: "get_language",
    }),
  },
  watch: {
    async lang() {
      this.getLanguage(this.lang);
      await this.getCarouselItems();
      await this.getMaterialsItems();
    },
  },
  methods: {
    handleOpenVidoDialog(file) {
      this.videoDialog = true;
      this.videoToOpen = file;
    },
    getLanguage(lang) {
      const languageEnum =
        lang === this.LanguageTextEnum.PT
          ? this.LanguageEnum.pt
          : this.LanguageEnum.en;

      this.language = languageEnum;
    },
    async getCarouselItems() {
      this.carouselItems = [];
      this.carouselItemsLoading = true;

      await this.apiService
        .getRequest(`b2bmaterials/videos/${this.language}/${true}`)
        .then((resp) => {
          const videos = resp.content;
          this.carouselItems = videos.filter((video) => video.visible);
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
      this.carouselItemsLoading = false;
    },
    async getMaterialsItems() {
      this.materialsItems = [];
      this.filesLoading = true;

      await this.apiService
        .getRequest(`b2bmaterials/files/${this.language}`)
        .then((resp) => {
          this.materialsItems = resp.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
      this.filesLoading = false;
    },
  },
};
</script>

<style scoped>
.carousel-container {
  max-width: 453px;
}

.blog-container {
  max-width: 448px;
  height: 255px;
  background-color: var(--dark);
  border-radius: 6px;
}

.blog-title {
  display: block;
  font-size: 22px;
  font-family: Source Sans Pro;
  letter-spacing: 0.15px;
  color: var(--white);
  margin-bottom: 16px;
}

.blog-sub-title {
  font-size: 14px;
  color: #9e9e9e;
  font-family: Source Sans Pro;
  line-height: 22px;
  letter-spacing: 0.1px;
}

.blog-content {
  padding: 20px;
}

.blog-link {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-family: Source Sans Pro;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: var(--primary);
  border-radius: 4px;
  border: 1px solid #9e9e9e;
  height: 36px;
  max-width: max-content;
  padding: 0px 16px;
  text-decoration: none;
  margin-top: 64px;
}

.insights-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.insights-title {
  color: var(--dark);
  font-size: 35px;
  font-family: Source Sans Pro;
  font-weight: 700;
  letter-spacing: 0.15px;
  margin-bottom: 57px;
}

.insights-sub-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 28px;
  font-family: Source Sans Pro;
  font-weight: 600;
  letter-spacing: 0.15px;
  margin-bottom: 12px;
}

.carousel-loading {
  display: grid;
  place-items: center;
  margin-top: 20px;
}

.files-loading {
  display: grid;
  place-items: center;
  margin-top: 60px;
  margin-bottom: 20px;
}

@media (max-width: 769px) {
  .carousel-container {
    width: 100%;
  }

  .insights-title {
    margin-bottom: 20px;
    font-size: 32px;
  }

  .insights-sub-title {
    font-size: 24px;
  }
}
</style>
