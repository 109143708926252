<template>
  <section>
    <MaterialB2BPartnerOrBankerComponent />
  </section>
</template>

<script>
import MaterialB2BPartnerOrBankerComponent from "./components/MaterialB2BPartnerOrBanker/MaterialB2BPartnerOrBankerComponent.vue";

export default {
  name: "MaterialsB2BPartnerOrBanker",
  components: {
    MaterialB2BPartnerOrBankerComponent,
  },
};
</script>

<style scoped></style>
