<template>
  <div class="table-container">
    <h2 class="table-title">{{ $t("materials") }}</h2>
    <v-simple-table class="table-content">
      <thead>
        <tr>
          <th>{{ $t("download") }}</th>
          <th>{{ $t("file") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(file, i) in files" :key="i">
          <td>
            <v-btn
              style="background: transparent; max-width: max-content"
              icon
              :download="file.name"
              class="px-2 ml-1 btnDownloadFile"
              :id="file.id"
              :name="file.name"
              color="primary"
              @click="handleDownloadFile(file)"
              min-width="0"
              :loading="fileDownloading === file.id"
              small
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </td>
          <td>{{ file.name }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "MaterialsTable",
  props: {
    files: Array,
  },
  data() {
    return {
      fileDownloading: null,
      apiService: new ApiService(),
    };
  },
  methods: {
    async handleDownloadFile(file) {
      this.fileDownloading = file.id;

      await this.apiService
        .postRequest(`b2bmaterials/files/${file.id}/download`)
        .then((resp) => {
          resp = "data:application/octet-stream;base64," + resp.message;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", file.name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.fileDownloading = null;
    },
  },
};
</script>

<style scoped>
.table-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.12),
    0px 0px 0px 1px rgba(0, 0, 0, 0.14), 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
  margin-top: 43px;
  margin-bottom: 60px;
}

.table-content {
  padding: 0px 20px;
  padding-bottom: 20px;
}

.table-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 28px;
  font-family: Source Sans Pro;
  font-weight: 600;
  letter-spacing: 0.15px;
  padding-left: 20px;
  padding-top: 12px;
}
</style>
